import { createWebHistory, createRouter } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'

import DashboardPage from '../pages/DashboardPage.vue'
import PatientsListPage from '../pages/patient-list/PatientsListPage.vue'
import LoginPage from '../pages/auth/LoginPage.vue'

const routes = [
  { path: '/', component: DashboardPage, meta: { requiresAuth: true } },
  {
    path: '/patients',
    component: PatientsListPage,
    meta: { requiresAuth: true },
  },
  { path: '/login', component: LoginPage },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, _, next) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (isLoading.value) {
    return
  } else if (requiresAuth && !isAuthenticated.value) {
    loginWithRedirect().then(() => next({ name: to.name }))
  } else {
    next()
  }
})

export default router
