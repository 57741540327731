import './assets/styles/index.css'

import { createApp } from 'vue'
import { createAuth0 } from '@auth0/auth0-vue'

import App from './App.vue'
import router from './router'

const domain = import.meta.env.VITE_APP_AUTH0_DOMAIN || 'anzolo.us.auth0.com'
const clientId = import.meta.env.VITE_APP_AUTH0_CLIENT_ID || ''
const audience = import.meta.env.VITE_APP_AUTH0_AUDIENCE || ''

createApp(App)
  .use(
    createAuth0({
      domain: domain,
      clientId: clientId,
      useRefreshTokens: true,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: audience,
        scope: 'openid profile email offline_access',
      },
    })
  )
  .use(router)
  .mount('#app')

/*
import { useAuth0 } from "@auth0/auth0-vue";
const { getAccessTokenSilently } = useAuth0();

// ...

async function fetchData() {
  try {
    // Получаем актуальный токен
    const token = await getAccessTokenSilently();

    // Затем можем использовать этот токен в заголовках запроса
    const response = await axios.get('my-api-url', {
      headers: { Authorization: `Bearer ${token}` }
    });

    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}
 */
