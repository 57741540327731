<script setup>
import { toRefs, computed } from 'vue'
import { useRoute } from 'vue-router'

import { Icon } from '@ui'

const props = defineProps({
  links: {
    type: Array,
    required: true,
    validator: links =>
      links.every(
        link =>
          typeof link.text === 'string' &&
          typeof link.isRouterLink === 'boolean' &&
          (typeof link.to === 'string' || typeof link.onClick === 'function') &&
          typeof link.icon === 'string'
      ),
  },
  baseLinkClass: {
    type: String,
    default:
      'flex items-center p-3 space-x-3 text-slate-500 hover:bg-slate-100 transition-all text-sm leading-none w-full rounded-sm font-semibold',
  },
  activeLinkClass: {
    type: String,
    default: '!bg-slate-200 text-slate-950',
  },
})

const { links, activeLinkClass } = toRefs(props)

const route = useRoute()

const computedLinks = computed(() =>
  links.value.map(link => ({
    ...link,
    isActive: link.isRouterLink && link.to === route.path,
  }))
)
</script>

<template>
  <ul class="flex flex-col space-y-1">
    <li v-for="link in computedLinks" :key="link.text" class="flex items-center">
      <component
        :is="link.isRouterLink ? 'router-link' : 'button'"
        :to="link.isRouterLink ? link.to : null"
        :class="[baseLinkClass, { [activeLinkClass]: link.isActive }]"
        @click="link.isRouterLink ? null : link.onClick"
      >
        <Icon :name="link.icon" class="size-4" />
        <span>{{ link.text }}</span>
      </component>
    </li>
  </ul>
</template>
