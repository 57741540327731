import * as heroMicroIcons from '@heroicons/vue/16/solid'
import * as heroMiniIcons from '@heroicons/vue/20/solid'
import * as heroOutlineIcons from '@heroicons/vue/24/outline'
import * as heroSolidIcons from '@heroicons/vue/24/solid'

type UniqueKeys<T> = {
  [K in keyof T]: K
}[keyof T]

export type IconNameType = UniqueKeys<
  typeof heroMicroIcons | typeof heroMiniIcons | typeof heroOutlineIcons | typeof heroSolidIcons
>

export const iconLibs = {
  micro: heroMicroIcons,
  mini: heroMiniIcons,
  outline: heroOutlineIcons,
  solid: heroSolidIcons,
}

export type IconType = {
  name: IconNameType
  lib?: 'micro' | 'mini' | 'solid' | 'outline'
}
