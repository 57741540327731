<script setup>
import { ref } from 'vue'

import IconLinkList from '@/components/shared/IconLinkList.vue'

const mainNavLinks = ref([
  {
    text: 'Dashboard',
    isRouterLink: true,
    to: '/',
    icon: 'CircleStackIcon',
  },
  {
    text: 'Patients',
    isRouterLink: true,
    to: '/patients',
    icon: 'UsersIcon',
  },
])
</script>

<template>
  <nav>
    <div>
      <RouterLink class="flex items-center justify-center px-4 py-8" to="/">
        <img class="block h-[42px]" src="@/assets/icons/logo-full.svg" alt="Anzolo Medical" />
      </RouterLink>
    </div>
    <div class="px-4">
      <IconLinkList :links="mainNavLinks" />
    </div>
  </nav>
</template>
