<script setup lang="ts">
import type { HTMLAttributes } from 'vue'

import { cn } from '@utils'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <thead :class="cn('bg-slate-50 [&_tr]:border-b', props.class)">
    <slot />
  </thead>
</template>
