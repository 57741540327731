<script setup lang="ts" generic="T">
import type { Table } from '@tanstack/vue-table'
import { computed } from 'vue'

import { Icon } from '@ui'

import { Button } from '../button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../dropdown-menu'

interface DataTableViewOptionsProps<T> {
  table: Table<T>
}

const props = defineProps<DataTableViewOptionsProps<T>>()

const columns = computed(() =>
  props.table.getAllColumns().filter(column => typeof column.accessorFn !== 'undefined' && column.getCanHide())
)
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button variant="outline" size="sm" class="ml-auto flex h-8">
        <Icon name="AdjustmentsHorizontalIcon" class="mr-2 size-4" />
        View
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" class="w-[150px]">
      <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
      <DropdownMenuSeparator />

      <DropdownMenuCheckboxItem
        v-for="column in columns"
        :key="column.id"
        class="capitalize"
        :checked="column.getIsVisible()"
        @update:checked="value => column.toggleVisibility(!!value)"
      >
        {{ column.id }}
      </DropdownMenuCheckboxItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
