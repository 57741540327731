<script setup lang="ts" generic="T">
import { cn } from '@utils'
import { Button, Icon } from '@ui'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../dropdown-menu'

import { TableColumnDef } from './types'

interface DataTableColumnHeaderProps<T> {
  column: TableColumnDef<T>
  title: string
  sorted?: 'asc' | 'desc' | false
  toggleSorting?: (desc: boolean) => void
  toggleVisibility?: (value: boolean) => void
}

withDefaults(defineProps<DataTableColumnHeaderProps<T>>(), {
  sorted: false,
  toggleSorting: () => undefined,
  toggleVisibility: () => undefined,
})
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<template>
  <div :class="cn('flex items-center space-x-2', $attrs.class ?? '')">
    <DropdownMenu>
      <DropdownMenuTrigger as-child>
        <Button
          variant="ghost"
          size="sm"
          class="-ml-3 h-8 data-[state=open]:bg-foreground data-[state=open]:text-white"
        >
          <span>{{ title }}</span>
          <!--          <Icon v-if="column.getIsSorted() === 'desc'" name="ArrowDownIcon" class="ml-2 size-4" />-->
          <!--          <Icon v-else-if="column.getIsSorted() === 'asc'" name="ArrowUpIcon" class="ml-2 size-4" />-->
          <Icon name="ArrowsUpDownIcon" class="ml-2 size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuItem @click="toggleSorting(false)">
          <Icon name="ArrowUpIcon" class="mr-2 size-3.5 text-muted-foreground/70" />
          Asc
        </DropdownMenuItem>
        <DropdownMenuItem @click="toggleSorting(true)">
          <Icon name="ArrowDownIcon" class="mr-2 size-3.5 text-muted-foreground/70" />
          Desc
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem @click="toggleVisibility(false)">
          <Icon name="EyeSlashIcon" class="mr-2 size-3.5 text-muted-foreground/70" />
          Hide
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </div>

  <!--  <div v-else :class="$attrs.class">-->
  <!--    {{ title }}-->
  <!--  </div>-->
</template>
