<script setup lang="ts" generic="T">
import type { Table } from '@tanstack/vue-table'
import { computed } from 'vue'

import { Icon } from '@ui'

import { Button } from '../button'
import { Input } from '../input'

import DataTableViewOptions from './DataTableViewOptions.vue'

interface DataTableToolbarProps<T> {
  table: Table<T>
}

const props = defineProps<DataTableToolbarProps<T>>()

const isFiltered = computed(() => props.table.getState().columnFilters.length > 0)
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex flex-1 items-center space-x-2">
      <Input
        placeholder="Search patients..."
        :model-value="(table.getColumn('name')?.getFilterValue() as string) ?? ''"
        class="h-8 w-[150px] lg:w-[250px]"
        @input="table.getColumn('name')?.setFilterValue($event.target.value)"
      />
      <!--      <DataTableFacetedFilter-->
      <!--        v-if="table.getColumn('status')"-->
      <!--        :column="table.getColumn('status')"-->
      <!--        title="Status"-->
      <!--        :options="statuses"-->
      <!--      />-->
      <Button v-if="isFiltered" variant="ghost" class="h-8 px-2 lg:px-3" @click="table.resetColumnFilters()">
        Reset
        <Icon name="XMarkIcon" class="ml-2 size-4" />
      </Button>
    </div>
    <DataTableViewOptions :table="table" />
  </div>
</template>
