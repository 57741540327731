import { h } from 'vue'

import type { Patient } from '@/schemas/patientSchema'
import { Checkbox, DataTableColumnHeader, DataTableRowActions } from '@ui'
import { TableColumnDef } from '@/components/ui/data-table/types'

export const columns: TableColumnDef<Patient>[] = [
  {
    id: 'select',
    header: ({ table }) =>
      h(Checkbox, {
        checked: table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate'),
        'onUpdate:checked': value => table.toggleAllPageRowsSelected(!!value),
        ariaLabel: 'Select all',
        class: 'translate-y-0.5',
      }),
    cell: ({ row }) =>
      h(Checkbox, {
        checked: row.getIsSelected(),
        'onUpdate:checked': value => row.toggleSelected(!!value),
        ariaLabel: 'Select row',
        class: 'translate-y-0.5',
      }),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: 'Name' }),
    cell: ({ row }) => h('span', { class: 'block text-nowrap' }, row.getValue('name')),
  },
  {
    accessorKey: 'status',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: 'Status' }),
    cell: ({ row }) => h('div', { class: '' }, row.getValue('status')),
  },
  {
    accessorKey: 'last_interaction',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: 'Interaction' }),
    cell: ({ row }) => h('div', { class: '' }, row.getValue('last_interaction')),
  },
  {
    accessorKey: 'last_interaction_date',
    header: ({ column }) => h(DataTableColumnHeader, { column, title: 'Date' }),
    cell: ({ row }) => h('div', { class: '' }, row.getValue('last_interaction_date')),
  },
  {
    id: 'actions',
    cell: ({ row }) => h(DataTableRowActions, { row }),
  },
]
