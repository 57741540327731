<script lang="ts" setup>
import type { HTMLAttributes } from 'vue'

import { cn } from '@utils'
import { Icon } from '@ui'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <li role="presentation" aria-hidden="true" :class="cn('[&>svg]:size-3.5', props.class)">
    <slot>
      <Icon name="ChevronRightIcon" />
    </slot>
  </li>
</template>
